var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "refFormObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var invalid = _ref.invalid;
        return [_c('b-modal', {
          attrs: {
            "id": "modal-add-documents-vn1a",
            "title": _vm.$t('reservation.addDocuments.title'),
            "title-class": "text-airline font-medium-4 font-weight-bolder",
            "header-bg-variant": "light-info",
            "centered": "",
            "size": "lg",
            "no-close-on-backdrop": "",
            "no-close-on-esc": ""
          },
          on: {
            "show": _vm.showHandle
          },
          scopedSlots: _vm._u([{
            key: "modal-footer",
            fn: function fn(_ref2) {
              var cancel = _ref2.cancel;
              return [_c('div', {
                staticClass: "w-100 text-center"
              }, [_c('b-button', {
                staticClass: "center rounded-pill px-1 px-md-2",
                attrs: {
                  "size": "md",
                  "variant": "outline-secondary"
                },
                on: {
                  "click": function click($event) {
                    return cancel();
                  }
                }
              }, [_vm._v(" " + _vm._s(_vm.$t('reservation.back')) + " ")]), _c('b-button', {
                directives: [{
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: 'rgba(113, 102, 240, 0.15)',
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: {
                    "400": true
                  }
                }],
                staticClass: "btn-gradient border-right-0 px-1 px-md-2 ml-75 ml-md-2",
                attrs: {
                  "pill": "",
                  "disabled": invalid || _vm.isDisabledSubmit
                },
                on: {
                  "click": _vm.submitHandle
                }
              }, [_c('div', {
                staticClass: "d-flex-center px-25"
              }, [_vm._v(" " + _vm._s(_vm.$t('reservation.confirmChange')) + " ")])])], 1)];
            }
          }], null, true)
        }, [_c('div', {
          staticClass: "mb-50"
        }, [_c('div', {
          staticClass: "font-medium-4 fw-700"
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.bookingCode')) + ": "), _c('span', {
          staticClass: "text-info text-uppercase"
        }, [_vm._v(_vm._s(_vm.bookingData.bookingCode))])]), _c('div', {
          staticClass: "text-warning font-italic"
        }, [_c('div', [_vm._v("(*) Lựa chọn hành khách để thêm Passport/CCCD")]), _c('div', [_vm._v("(**) Vui lòng không nhập thông tin khống")]), _c('div', [_vm._v("(***) Bạn sẽ chịu trách nhiệm bồi thường cho hãng nếu thông tin sai")])])]), _c('div', _vm._l(_vm.paxData, function (paxDocumentInfo, paxDocumentInfoIndex) {
          return _c('div', {
            key: paxDocumentInfo.paxId
          }, [_c('b-form-checkbox', {
            attrs: {
              "id": "paxDocumentInfo-".concat(paxDocumentInfoIndex),
              "name": "paxDocumentInfo-".concat(paxDocumentInfoIndex)
            },
            model: {
              value: paxDocumentInfo.isCheck,
              callback: function callback($$v) {
                _vm.$set(paxDocumentInfo, "isCheck", $$v);
              },
              expression: "paxDocumentInfo.isCheck"
            }
          }, [_c('span', {
            staticClass: "fw-600 text-uppercase"
          }, [_vm._v(" " + _vm._s(paxDocumentInfo.paxId) + ". " + _vm._s(paxDocumentInfo.title) + " " + _vm._s(paxDocumentInfo.lastName) + " " + _vm._s(paxDocumentInfo.firstName) + " ")])]), paxDocumentInfo.isCheck ? _c('div', [_c('b-input-group', {
            scopedSlots: _vm._u([{
              key: "prepend",
              fn: function fn() {
                return [_c('b-form-select', {
                  attrs: {
                    "options": _vm.documentTypeOptions
                  },
                  model: {
                    value: paxDocumentInfo.documentType,
                    callback: function callback($$v) {
                      _vm.$set(paxDocumentInfo, "documentType", $$v);
                    },
                    expression: "paxDocumentInfo.documentType"
                  }
                })];
              },
              proxy: true
            }], null, true)
          }, [['C', 'P'].includes(paxDocumentInfo.documentType) ? _c('b-form-input', {
            attrs: {
              "lazy-formatter": "",
              "formatter": _vm.trimAllInputAndUpper,
              "placeholder": paxDocumentInfo.documentType === 'P' ? _vm.$t('flight.Passport') : _vm.$t('reservation.addDocuments.CCCD')
            },
            model: {
              value: paxDocumentInfo.number,
              callback: function callback($$v) {
                _vm.$set(paxDocumentInfo, "number", $$v);
              },
              expression: "paxDocumentInfo.number"
            }
          }) : _c('flat-pickr', {
            staticClass: "form-control",
            attrs: {
              "name": _vm.$t('flight.dob'),
              "config": Object.assign({}, _vm.configFlatPickr, {
                altInputClass: 'form-control'
              }),
              "placeholder": _vm.$t('flight.dob')
            },
            model: {
              value: paxDocumentInfo.birthday,
              callback: function callback($$v) {
                _vm.$set(paxDocumentInfo, "birthday", $$v);
              },
              expression: "paxDocumentInfo.birthday"
            }
          })], 1), paxDocumentInfo.documentType === 'P' ? _c('div', {
            staticClass: "d-flex flex-column gap-2 my-50"
          }, [_c('div', [_c('validation-provider', {
            attrs: {
              "rules": "required",
              "name": _vm.$t('flight.Nationality'),
              "vid": "passport-nationality-".concat(paxDocumentInfoIndex)
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(validationContext) {
                return [_c('b-form-group', {
                  staticClass: "mb-0 p-0",
                  class: validationContext.errors[0] ? 'is-invalid' : ''
                }, [_c('v-select', {
                  attrs: {
                    "id": "passport-nationality-".concat(paxDocumentInfoIndex),
                    "options": _vm.nationalOptions,
                    "label": "longCode",
                    "reduce": function reduce(val) {
                      return val.longCode;
                    },
                    "placeholder": _vm.$t('flight.Nationality'),
                    "searchable": "",
                    "filterable": false
                  },
                  on: {
                    "open": _vm.openCountrySelect,
                    "search": _vm.searchCountrySelect
                  },
                  scopedSlots: _vm._u([{
                    key: "selected-option",
                    fn: function fn(item) {
                      return [_c('div', {
                        staticClass: "cursor-pointer"
                      }, [_c('b', [_vm._v(_vm._s(item.countryName))]), _vm._v(" ("), _c('span', {
                        staticClass: "text-warning font-weight-bolder"
                      }, [_vm._v(_vm._s(item.longCode))]), _vm._v(") ")])];
                    }
                  }, {
                    key: "option",
                    fn: function fn(item) {
                      return [_c('div', {
                        staticClass: "cursor-pointer"
                      }, [_c('b', [_vm._v(_vm._s(item.countryName))]), _vm._v(" ("), _c('span', {
                        staticClass: "text-warning font-weight-bolder"
                      }, [_vm._v(_vm._s(item.longCode))]), _vm._v(") ")])];
                    }
                  }, {
                    key: "spinner",
                    fn: function fn(_ref3) {
                      var loading = _ref3.loading;
                      return [loading ? _c('div', {
                        staticClass: "vs__spinner",
                        staticStyle: {
                          "border-left-color": "rgba(88, 151, 251, 0.71)"
                        }
                      }) : _vm._e()];
                    }
                  }, {
                    key: "no-options",
                    fn: function fn() {
                      return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
                    },
                    proxy: true
                  }], null, true),
                  model: {
                    value: paxDocumentInfo.nationality,
                    callback: function callback($$v) {
                      _vm.$set(paxDocumentInfo, "nationality", $$v);
                    },
                    expression: "paxDocumentInfo.nationality"
                  }
                }), validationContext.errors[0] ? _c('b-tooltip', {
                  attrs: {
                    "target": "passport-nationality-".concat(paxDocumentInfoIndex),
                    "triggers": "hover focus",
                    "placement": "top",
                    "boundary": "window",
                    "variant": "danger",
                    "title": validationContext.errors[0]
                  }
                }, [_c('span', {
                  staticClass: "text-white py-25 mb-0"
                }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
              }
            }], null, true)
          })], 1), _c('div', [_c('validation-provider', {
            attrs: {
              "rules": "required",
              "name": _vm.$t('flight.dob'),
              "vid": "passport-birthday-".concat(paxDocumentInfoIndex)
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(validationContext) {
                return [_c('b-form-group', {
                  staticClass: "mb-0",
                  attrs: {
                    "id": "passport-birthday-".concat(paxDocumentInfoIndex)
                  }
                }, [_c('flat-pickr', {
                  staticClass: "d-none",
                  model: {
                    value: paxDocumentInfo.birthday,
                    callback: function callback($$v) {
                      _vm.$set(paxDocumentInfo, "birthday", $$v);
                    },
                    expression: "paxDocumentInfo.birthday"
                  }
                }), _c('div', {
                  staticClass: "form-control p-0",
                  class: validationContext.errors[0] ? 'is-invalid' : ''
                }, [_c('flat-pickr', {
                  staticClass: "form-control px-50 py-0",
                  attrs: {
                    "id": "passport-birthday-".concat(paxDocumentInfoIndex),
                    "name": _vm.$t('flight.dob'),
                    "config": Object.assign({}, _vm.configFlatPickr, {
                      altInputClass: 'form-control input px-1 py-0 border-transparent'
                    }),
                    "placeholder": _vm.$t('flight.dob')
                  },
                  model: {
                    value: paxDocumentInfo.birthday,
                    callback: function callback($$v) {
                      _vm.$set(paxDocumentInfo, "birthday", $$v);
                    },
                    expression: "paxDocumentInfo.birthday"
                  }
                })], 1), validationContext.errors[0] ? _c('b-tooltip', {
                  attrs: {
                    "target": "passport-birthday-".concat(paxDocumentInfoIndex),
                    "triggers": "hover focus",
                    "placement": "top",
                    "boundary": "window",
                    "variant": "danger",
                    "title": validationContext.errors[0]
                  }
                }, [_c('span', {
                  staticClass: "text-white py-25 mb-0"
                }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
              }
            }], null, true)
          })], 1), _c('div', [_c('validation-provider', {
            attrs: {
              "rules": "required",
              "name": _vm.$t('flight.placeIssue'),
              "vid": "passport-placeIssue-".concat(paxDocumentInfoIndex)
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(validationContext) {
                return [_c('b-form-group', {
                  staticClass: "mb-0 p-0",
                  class: validationContext.errors[0] ? 'is-invalid' : ''
                }, [_c('v-select', {
                  attrs: {
                    "id": "passport-placeIssue-".concat(paxDocumentInfoIndex),
                    "options": _vm.nationalOptions,
                    "label": "longCode",
                    "reduce": function reduce(val) {
                      return val.longCode;
                    },
                    "placeholder": _vm.$t('flight.placeIssue'),
                    "searchable": "",
                    "filterable": false
                  },
                  on: {
                    "open": _vm.openCountrySelect,
                    "search": _vm.searchCountrySelect
                  },
                  scopedSlots: _vm._u([{
                    key: "selected-option",
                    fn: function fn(item) {
                      return [_c('div', {
                        staticClass: "cursor-pointer"
                      }, [_c('b', [_vm._v(_vm._s(item.countryName))]), _vm._v(" ("), _c('span', {
                        staticClass: "text-warning font-weight-bolder"
                      }, [_vm._v(_vm._s(item.longCode))]), _vm._v(") ")])];
                    }
                  }, {
                    key: "option",
                    fn: function fn(item) {
                      return [_c('div', {
                        staticClass: "cursor-pointer"
                      }, [_c('b', [_vm._v(_vm._s(item.countryName))]), _vm._v(" ("), _c('span', {
                        staticClass: "text-warning font-weight-bolder"
                      }, [_vm._v(_vm._s(item.longCode))]), _vm._v(") ")])];
                    }
                  }, {
                    key: "spinner",
                    fn: function fn(_ref4) {
                      var loading = _ref4.loading;
                      return [loading ? _c('div', {
                        staticClass: "vs__spinner",
                        staticStyle: {
                          "border-left-color": "rgba(88, 151, 251, 0.71)"
                        }
                      }) : _vm._e()];
                    }
                  }, {
                    key: "no-options",
                    fn: function fn() {
                      return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
                    },
                    proxy: true
                  }], null, true),
                  model: {
                    value: paxDocumentInfo.isCountry,
                    callback: function callback($$v) {
                      _vm.$set(paxDocumentInfo, "isCountry", $$v);
                    },
                    expression: "paxDocumentInfo.isCountry"
                  }
                }), validationContext.errors[0] ? _c('b-tooltip', {
                  attrs: {
                    "target": "passport-placeIssue-".concat(paxDocumentInfoIndex),
                    "triggers": "hover focus",
                    "placement": "top",
                    "boundary": "window",
                    "variant": "danger",
                    "title": validationContext.errors[0]
                  }
                }, [_c('span', {
                  staticClass: "text-white py-25 mb-0"
                }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
              }
            }], null, true)
          })], 1), _c('div', [_c('validation-provider', {
            attrs: {
              "rules": "required",
              "name": _vm.$t('flight.expirationDate'),
              "vid": "passport-expirationDate-".concat(paxDocumentInfoIndex)
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(validationContext) {
                return [_c('b-form-group', {
                  staticClass: "mb-0",
                  attrs: {
                    "id": "passport-expirationDate-".concat(paxDocumentInfoIndex)
                  }
                }, [_c('flat-pickr', {
                  staticClass: "d-none",
                  model: {
                    value: paxDocumentInfo.endDate,
                    callback: function callback($$v) {
                      _vm.$set(paxDocumentInfo, "endDate", $$v);
                    },
                    expression: "paxDocumentInfo.endDate"
                  }
                }), _c('div', {
                  staticClass: "form-control p-0",
                  class: validationContext.errors[0] ? 'is-invalid' : ''
                }, [_c('flat-pickr', {
                  staticClass: "form-control px-50 py-0",
                  attrs: {
                    "id": "passport-expirationDate-".concat(paxDocumentInfoIndex),
                    "name": _vm.$t('flight.expirationDate'),
                    "config": Object.assign({}, _vm.configFlatPickrPassport, {
                      altInputClass: 'form-control input px-1 py-0 border-transparent'
                    }),
                    "placeholder": "".concat(_vm.$t('flight.expirationDate'))
                  },
                  model: {
                    value: paxDocumentInfo.endDate,
                    callback: function callback($$v) {
                      _vm.$set(paxDocumentInfo, "endDate", $$v);
                    },
                    expression: "paxDocumentInfo.endDate"
                  }
                })], 1), validationContext.errors[0] ? _c('b-tooltip', {
                  attrs: {
                    "target": "passport-expirationDate-".concat(paxDocumentInfoIndex),
                    "triggers": "hover focus",
                    "placement": "top",
                    "boundary": "window",
                    "variant": "danger",
                    "title": validationContext.errors[0]
                  }
                }, [_c('span', {
                  staticClass: "text-white py-25 mb-0"
                }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
              }
            }], null, true)
          })], 1), _c('div', [_c('validation-provider', {
            attrs: {
              "rules": "required",
              "name": _vm.$t('flight.gender'),
              "vid": "passport-gender-".concat(paxDocumentInfoIndex)
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(validationContext) {
                return [_c('b-form-group', {
                  staticClass: "mb-0",
                  attrs: {
                    "id": "passport-gender-".concat(paxDocumentInfoIndex)
                  }
                }, [_c('v-select', {
                  staticClass: "w-100 p-0",
                  attrs: {
                    "reduce": function reduce(val) {
                      return val.value;
                    },
                    "label": "label",
                    "options": _vm.genderOptions.filter(function (item) {
                      return item.value !== 'OTHER';
                    }),
                    "placeholder": _vm.$t('flight.gender')
                  },
                  scopedSlots: _vm._u([{
                    key: "option",
                    fn: function fn(data) {
                      return [_c('span', [_vm._v(" " + _vm._s(_vm.$t(data.label)) + " ")])];
                    }
                  }, {
                    key: "selected-option",
                    fn: function fn(data) {
                      return [_c('span', [_vm._v(" " + _vm._s(_vm.$t(data.label)) + " ")])];
                    }
                  }, {
                    key: "no-options",
                    fn: function fn() {
                      return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
                    },
                    proxy: true
                  }], null, true),
                  model: {
                    value: paxDocumentInfo.gender,
                    callback: function callback($$v) {
                      _vm.$set(paxDocumentInfo, "gender", $$v);
                    },
                    expression: "paxDocumentInfo.gender"
                  }
                }), validationContext.errors[0] ? _c('b-tooltip', {
                  attrs: {
                    "target": "passport-gender-".concat(paxDocumentInfoIndex),
                    "triggers": "hover focus",
                    "placement": "top",
                    "boundary": "window",
                    "variant": "danger",
                    "title": validationContext.errors[0]
                  }
                }, [_c('span', {
                  staticClass: "text-white py-25 mb-0"
                }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
              }
            }], null, true)
          })], 1)]) : _vm._e(), _vm.flightDate && _vm.checkPassportLessThan6MonthsBeforeFlightDate(paxDocumentInfo.endDate, _vm.flightDate) ? _c('div', {
            staticClass: "text-danger"
          }, [_c('small', [_vm._v(_vm._s(_vm.$t('reservation.passportLessThan6MonthsBeforeFlight')))])]) : _vm._e()], 1) : _vm._e()], 1);
        }), 0)]), _c('ModalShowText', {
          attrs: {
            "text": _vm.responseCommand,
            "title-modal": _vm.$t('reservation.addDocuments.title'),
            "id-modal": _vm.idModal
          }
        })];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }